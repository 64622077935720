const AWS = require("aws-sdk")
AWS.config.update({ region: "eu-west-2" })
AWS.config.update({ credentials: {
    accessKeyId: process.env.GATSBY_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.GATSBY_AWS_SECRET_ACCESS_KEY
}})
const dynamo = new AWS.DynamoDB.DocumentClient()

export const getBakeDaysFromDynamo = async () => {
    const params = {
        ExpressionAttributeNames: {
            "#D": "Date",
            "#RO": "RemainingOrders"
        },
        ProjectionExpression: "#D, #RO",
        TableName: "orders_capacity"
    }
    return dynamo.scan(params).promise()
}
