import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'
import {colours} from "../styles/colours";

const aboutSection = {
    backgroundColor: colours.red,
    paddingTop: "20px",
    margin: 0
}

const aboutContent = {
    maxWidth: "800px",
    paddingBottom: "30px",
    margin: "auto",
    borderTop: `0.4em solid ${colours.yellow}`
}

const aboutHeader = {
    textAlign: "center",
    margin: "10px",
    color: colours.yellow
}

const imgAndText = {
    display: "flex",
    flexDirection: "column",
}

const suzeImg = {
    width: "100%",
    marginBottom: "30px"
}

export default function AboutSuze({aboutImage, aboutDescription}) {
    return (
       <div style={aboutSection}>
           <div style={aboutContent}>
               <h1 style={aboutHeader}>who is suze?</h1>
               <div style={imgAndText}>
                   <GatsbyImage style={suzeImg} image={getImage(aboutImage)} alt={"Photo of Suze"}/>
                   <ReactMarkdown className={"aboutSuzeDescription"}>{aboutDescription}</ReactMarkdown>
               </div>
           </div>
       </div>
    )
}
