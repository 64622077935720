import {GatsbyImage, getImage} from "gatsby-plugin-image";
import React from "react";
import {useBetween} from "use-between";
import {GlobalStates} from "../utils/useBetweenDefinition";
import {colours} from "../styles/colours";

const sizeSelection = {
    background: colours.red,
    paddingBottom: "20px",
    paddingTop: "10px",
    width: "90%",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    margin: "0 auto",
}

const sizeLeftBox = {
    width: "50%",
    float: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
}

const sizeRightBox = {
    ...sizeLeftBox,
    float: "right",
}

const sizeIconWrapper = (isMobile) => ({
    flex: "1",
    width: isMobile? "70%": "35%",
    margin: "5px auto 5px",
})

const sizeSelectionIcon = {
    width: "100%",
}

const sizeSelectionText = {
    margin: "auto",
    marginTop: "5px",
    marginBottom: "5px",
    flex: "1",
    width: "100%",
    color: colours.yellow,
    textAlign: "center",
}

const selectionIndicator = (activeSize) => ({
    height: "10px",
    width: "50%",
    backgroundColor: colours.yellow,
    marginTop: "-10px",
    transition: "margin-left 0.2s",
    marginLeft: (size => size)(activeSize),
})

export default function BrownieSize({homePageAssets}) {
    const { activeSize, setActiveSize, isMobile} = useBetween(GlobalStates);

    return (
        <>
            <div style={sizeSelection}>
                <div role="button" tabIndex={0} onClick={() => setActiveSize("0")} onKeyDown={() => setActiveSize("0")} style={sizeLeftBox}>
                    <h2 style={sizeSelectionText}>Loads</h2>
                    <div style={sizeIconWrapper(isMobile)}>
                        <GatsbyImage style={sizeSelectionIcon} alt={"Single Layer Size Icon"} image={getImage(homePageAssets.singleLayerIcon)}/>
                    </div>
                    <h4 style={sizeSelectionText}>{`£${homePageAssets.priceNormalSize}`}</h4>
                </div>
                <div role="button" tabIndex={0} onClick={() => setActiveSize("50%")} onKeyDown={() => setActiveSize("50%")} style={sizeRightBox}>
                    <h2 style={sizeSelectionText}>Even More</h2>
                    <div style={sizeIconWrapper(isMobile)}>
                        <GatsbyImage style={sizeSelectionIcon} alt={"Double Layer Size Icon"} image={getImage(homePageAssets.doubleLayerIcon)}/>
                    </div>
                    <h4 style={sizeSelectionText}>{`£${homePageAssets.priceDoubleSize}`}</h4>
                </div>
            </div>
            <div style={selectionIndicator(activeSize)} />
        </>
    )
}
