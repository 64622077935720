import * as React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import { colours } from "../styles/colours"
import InstagramIcon from '@material-ui/icons/Instagram';
import {useBetween} from "use-between";
import { GlobalStates } from "../utils/useBetweenDefinition";

const maxImageWidth = 400

const header = {
    background: colours.red,
    width: "100%",
    marginTop: 0,
    position: "relative",
    zIndex: 10,
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.6)"
}

const headerContent = {
    maxWidth: "800px",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
}

const headerContentLeft = {
    width: "70%",
    float: "left"
}

const logo = (isMobile) => ({
    width: isMobile ? "80%": `${maxImageWidth}px`,
    margin: "0px",
})

const headerContentRight = {
    width: "30%",
    textAlign: "right",
    display: "inline"
}

const instagramLink = {
    textAlign: "center",
    textShadow: "none",
    backgroundImage: "none",
}

const instagram = (isMobile) => ({
    width: isMobile ? "100%": `${maxImageWidth/2}px`,
    height: "80%",
    margin: "auto",
    color: "white"
})

export default function Header({pageAssets}) {
    const { isMobile} = useBetween(GlobalStates)

    return (
        <div style={header} className={"header"}  key={'header'}>
            <div style={headerContent}>
                <div style={headerContentLeft}>
                    <GatsbyImage style={logo(isMobile)} className={"logo"} alt={"logo"} image={getImage(pageAssets.logo)}/>
                </div>
                <div style={headerContentRight}>
                    <a style={instagramLink} target="_blank" rel="noreferrer" href={"https://www.instagram.com/suze_bakes/"}>
                        <InstagramIcon style={instagram(isMobile)} className={"instagramIcon"}/>
                    </a>
                </div>
            </div>
        </div>
    )
}
