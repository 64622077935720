import React from "react";
import {useBetween} from "use-between";
import Select from 'react-select';
import {GlobalStates} from "../utils/useBetweenDefinition";

const selectDivStyle = (isMobile) => ({
    width: isMobile ? "95%": "75%",
    margin: "auto"
})

const getBrownieOptionsFromBrownies = (brownies) => {
    return brownies.map((b) => {
        const index = b.node.positionId
        return {
            value: index,
            label: b.node.flavour
        }}
    )
}

export default function BrownieFlavourSelection1({brownies}) {
    const { setActiveFlavour1,
        isMobile} = useBetween(GlobalStates);

    return (
       <div style={selectDivStyle(isMobile)}>
           <Select instanceId={1} options={getBrownieOptionsFromBrownies(brownies)} onChange={selected => {
               return setActiveFlavour1(selected.value)
           }}/>
       </div>
    )
}
