import React from "react";
import {useBetween} from "use-between";
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import {GlobalStates} from "../utils/useBetweenDefinition";
import {colours} from "../styles/colours";

const selectDivStyle = (isMobile) => ({
    width: isMobile ? "95%": "75%",
    margin: "auto",
    display: "flex"
})

const checkboxDiv = (isMobile) => ({
    width: isMobile ? "15%": "10%",
    float: "left",
})

const selectDiv = (isMobile) => ({
    width: isMobile ? "85%": "90%",
    float: "right",
})

const checkboxOffText = {
    color: colours.grey,
    paddingX: "4%",
    marginBottom: "0"
}

const getBrownieOptionsFromBrownies = (brownies) => {
    return brownies.map((b) => {
        const index = b.node.positionId
        return {
            value: index,
            label: b.node.flavour
        }}
    )
}

export default function BrownieFlavourSelection2({brownies}) {
    const { setActiveFlavour2,
            useSecondFlavour, setUseSecondFlavour,
            isMobile} = useBetween(GlobalStates);

    return (
        <div style={selectDivStyle(isMobile)}>
            <div style={checkboxDiv(isMobile)}>
                <Checkbox onChange={(e) => setUseSecondFlavour(e.target.checked)}/>
            </div>
            <div style={selectDiv(isMobile)}>
                {useSecondFlavour ?
                    <Select instanceId={2} options={getBrownieOptionsFromBrownies(brownies)} onChange={selected => {
                       return setActiveFlavour2(selected.value)
                   }}/>
                   :
                   <p style={checkboxOffText}>All brownies will be of flavour 1. Select the checkbox to choose a secondary flavour</p>
               }
            </div>
       </div>
    )
}
