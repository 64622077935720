import React from "react";
import {useBetween} from "use-between";
import {GlobalStates} from "../utils/useBetweenDefinition";
import {colours} from "../styles/colours";
import {useAlert} from "react-alert";

const selection = {
    background: colours.red,
    paddingBottom: "20px",
    paddingTop: "10px",
    width: "90%",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    margin: "auto"
}

const selectionBox = {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
}

const categoryText = {
    margin: "auto",
    marginTop: "5px",
    marginBottom: "5px",
    flex: "1",
    width: "100%",
    color: colours.yellow,
    textAlign: "center",
}

const selectionIndicator = (activeDietaryRequirements) => ({
    height: "10px",
    width: "50%",
    backgroundColor: colours.yellow,
    marginTop: "-10px",
    transition: "margin-left 0.2s",
    marginLeft: (size => size)(activeDietaryRequirements),
})

export default function BrownieDietaryRequirements({brownies}) {
    const { activeDietaryRequirements, setActiveDietaryRequirements,
            activeFlavour1, activeFlavour2, useSecondFlavour} = useBetween(GlobalStates);
    const alert = useAlert()

    const validSelection = (selection) => {
        const canBeSelection = selection === "50%" ? "canBeGlutenFree": "canBeVegan"

        if (activeFlavour1 === -1) {
            alert.show("Please select a flavour first")
            return false
        }
        if (!brownies[activeFlavour1].node[canBeSelection]) {
            alert.show(`Sorry, that option isn't available with ${brownies[activeFlavour1].node.flavour}`)
            return false
        }
        if (!useSecondFlavour) {
            return true
        }
        if (activeFlavour2 === -1) {
            alert.show("Please select a flavour 2 or uncheck the box")
            return false
        }
        if (!brownies[activeFlavour2].node[canBeSelection]) {
            alert.show(`Sorry, that option isn't available with ${brownies[activeFlavour2].node.flavour}`)
            return false
        }
        return true
    }

    React.useEffect(() => {
        setActiveDietaryRequirements("0")
    }, [activeFlavour1, activeFlavour2, setActiveDietaryRequirements])

    return (
        <>
            <div style={selection}>
                <div role={"button"} tabIndex={0} onClick={() => setActiveDietaryRequirements("0")} onKeyDown={() => setActiveDietaryRequirements("0")} style={selectionBox}>
                    <h2 style={categoryText}>None</h2>
                </div>
                <div role={"button"} tabIndex={0} onClick={() => validSelection("50%") ? setActiveDietaryRequirements("50%"): ""} onKeyDown={() => validSelection("50%") ? setActiveDietaryRequirements("50%"): ""} style={selectionBox}>
                    <h2 style={categoryText}>Gluten Free</h2>
                </div>
            </div>
            <div style={selectionIndicator(activeDietaryRequirements)} />
        </>
    )
    // add in once vegan is available
        // <div onClick={() => validSelection("66%") ? setActiveDietaryRequirements("66%"): ""} style={selectionBox}>
        //     <h2 style={categoryText}>Vegan</h2>
        // </div>
}
