import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {useBetween} from "use-between";
import {GlobalStates} from "../utils/useBetweenDefinition";

const browniesSelectionContent = {
    maxWidth: "800px",
    margin: "auto",
}

const brownieFlavourSelection = {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between"
}

const browniePreviews = {
    width: "24%",
    marginBottom: "10px",
    transition: "border 0.2s ease"
}

export default function FlavourSelection({brownies}) {
    const { activeFlavour, setActiveFlavour} = useBetween(GlobalStates);

    const sortedBrownies = brownies.sort((a,b) => a.node.positionId - b.node.positionId)
    return (
            <div style={browniesSelectionContent}>
                <div style={brownieFlavourSelection}>
                    {sortedBrownies.map((b) => {
                        const index = b.node.positionId
                        const previewImg = getImage(b.node.images[0])
                        return (
                            <div key={index} role="button" tabIndex={0} style={{...browniePreviews, border: (activeFlavour === index ? "10px solid white" : "")}} onClick={() => setActiveFlavour(index)} onKeyDown={() => setActiveFlavour(index)}>
                                <GatsbyImage alt={b.node.flavour} image={previewImg}/>
                            </div>
                        )
                    })}
                </div>
            </div>
    )
}
