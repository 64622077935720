import React from "react";
import {useBetween} from "use-between";
import {GlobalStates} from "../utils/useBetweenDefinition";
import {colours} from "../styles/colours";

const selection = {
    background: colours.red,
    paddingBottom: "20px",
    paddingTop: "10px",
    width: "90%",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    margin: "auto"
}

const selectionBox = {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
}

const categoryText = {
    margin: "auto",
    marginTop: "5px",
    marginBottom: "5px",
    flex: "1",
    width: "100%",
    color: colours.yellow,
    textAlign: "center",
}

const selectionIndicator = (activeDietaryRequirements) => ({
    height: "10px",
    width: "50%",
    backgroundColor: colours.yellow,
    marginTop: "-10px",
    transition: "margin-left 0.2s",
    marginLeft: (size => size)(activeDietaryRequirements),
})

export default function BrownieCollectDeliver({homePageAssets}) {
    const { willDeliver, setWillDeliver, activeSize} = useBetween(GlobalStates)

    const sizeNameEnum = {
        0: "regularSizeShipping",
        "50%": "largeSizeShipping"
    }

    return (
        <>
            <div style={selection}>
                <div role={"button"} tabIndex={0} onClick={() => setWillDeliver("0")} onKeyDown={() => setWillDeliver("0")} style={selectionBox}>
                    <h2 style={categoryText}>Delivery</h2>
                    <h2 style={{...categoryText, fontSize: "x-large"}}>£{homePageAssets[sizeNameEnum[activeSize]]}</h2>
                </div>
                <div role={"button"} tabIndex={0} onClick={() => setWillDeliver("50%")} onKeyDown={() => setWillDeliver("50%")} style={selectionBox}>
                    <h2 style={categoryText}>Collection</h2>
                    <h2 style={{...categoryText, fontSize: "x-large"}}>£0</h2>
                </div>
            </div>
            <div style={selectionIndicator(willDeliver)} />
        </>
    )
}
