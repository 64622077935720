import React from 'react';
import {navigate} from 'gatsby';
import {useAlert} from 'react-alert'
import {loadStripe} from '@stripe/stripe-js';
import {colours} from "../styles/colours";
import axios from "axios";
import {useBetween} from "use-between";
import {GlobalStates} from "../utils/useBetweenDefinition";

const paymentSection = {
    backgroundColor: colours.yellow,
    margin: 0,
    padding: "10px",
    display: "flex"
}

const paymentContent = {
    maxWidth: "600px",
    width: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
}

const buttonDiv = (isMobile) => ({
    position: "relative",
    float: "right",
    margin: "auto",
    width: isMobile ? "55%" : "70%",
    height: isMobile ? "5em" : "3.5em",
})

const checkoutButton = (isMobile) => ({
    backgroundColor: colours.red,
    padding: "2% 5%",
    width: isMobile ? "90%" : "50%",
    height: "2.5em",
    position: "absolute",
    right: 0,
    bottom: 0
})

const checkoutText = {
    margin: "auto",
    color: colours.yellow,
    fontSize: "x-large",
    fontWeight: "bold",
}

const discountCodeDiv = (isMobile) => ({
    width: isMobile ? "45%": "30%",
    float: "left",
    display: "flex",
    flexDirection: "column",
    height: isMobile ? "5em" : "3.5em",
})

const discountCodeText = {
    margin: "auto",
}

const discountCodeInput = {
    width: "95%",
    margin: "0 auto"
}

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

export const Payments = ({browniesAssets, homePageAssets, discountCodes}) => {
    const alert = useAlert()
    const { useSecondFlavour,
            activeSize,
            activeBakeDay,
            activeDietaryRequirements,
            activeFlavour1,
            activeFlavour2,
            orderNotes,
            discountCode, setDiscountCode,
            willDeliver,
            isMobile} = useBetween(GlobalStates);

    const getDietaryRequirements = () => {
        switch (activeDietaryRequirements) {
            case "0":
                return "None"
            case "50%":
                return " Gluten Free"
            case "66%":
                return "Vegan"
            default:
                return "error"
        }
    }
    const handleClick = async (event) => {
        if (activeBakeDay === -1) {
            alert.show('Please select a bake day')
            return
        }
        if (activeFlavour1 === -1) {
            alert.show('Please select a flavour 1')
            return
        }
        if (useSecondFlavour && activeFlavour2 === -1) {
            alert.show('Please select a flavour 2 or uncheck the secondary flavour box')
            return
        }

        const loaderDiv = document.getElementById("loader")
        loaderDiv.classList.add("loader")

        const stripe = await stripePromise;

        let price = activeSize === "0" ? homePageAssets.priceNormalSize * 100 : homePageAssets.priceDoubleSize * 100
        let shippingPrice = activeSize === "0" ? homePageAssets.regularSizeShipping * 100 : homePageAssets.largeSizeShipping * 100

        const codeDefinition = discountCodes.edges.find((dc) => dc.node.code === discountCode)
        if (codeDefinition !== undefined) {
            if (codeDefinition.node.percentageDiscount === 100) {
                return await navigate("cashorder/index.html",{
                    state: {
                        flavour1: browniesAssets[activeFlavour1].node.flavour,
                        flavour2: useSecondFlavour ? browniesAssets[activeFlavour2].node.flavour : "No Second Flavour",
                        size: activeSize === "0" ? "Loads" : "Even More",
                        price,
                        shippingPrice: willDeliver === "0" ? shippingPrice: 0,
                        date: activeBakeDay,
                        type: getDietaryRequirements(),
                        orderNotes,
                        deliver: willDeliver === "0"
                    }
                })
            }
            price = price * (1-codeDefinition.node.percentageDiscount/100)
            shippingPrice = shippingPrice * (1-codeDefinition.node.percentageDiscount/100)
        }

        try {
            const response = await axios.post("https://x9ot4cac78.execute-api.eu-west-2.amazonaws.com/Prod/payments", {
                flavour1: browniesAssets[activeFlavour1].node.flavour,
                flavour2: useSecondFlavour ? browniesAssets[activeFlavour2].node.flavour : "No Second Flavour",
                size: activeSize === "0" ? "Loads" : "Even More",
                price,
                shippingPrice: willDeliver === "0" ? shippingPrice: 0,
                date: activeBakeDay,
                type: getDietaryRequirements(),
                orderNotes,
                deliver: willDeliver === "0"
            })

            const session = response.data;
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });
            if (result.error) {
                throw Error
            }
        } catch (e) {
            console.log(e)
            alert.show("Sorry! Something went wrong on our end :(")
            loaderDiv.classList.remove("loader")
        }
    };

    return (
        <div style={paymentSection}>
            <div style={paymentContent}>
                <div style={discountCodeDiv(isMobile)}>
                    <p style={discountCodeText}>Discount Code</p>
                    <input type={"text"} style={discountCodeInput} onChange={(e) => setDiscountCode(e.target.value)}/>
                </div>
                <div style={buttonDiv(isMobile)}>
                    <button style={checkoutButton(isMobile)} role="link" onClick={handleClick}>
                        <div id={"loader"} style={{margin: "0 auto 200%"}}>
                            <h3 style={checkoutText} className={"checkoutText"}>Checkout</h3>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};
