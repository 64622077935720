import React from "react"
import {useBetween} from "use-between";
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { colours } from "../styles/colours"
import FlavourSelection from "./brownie-flavour-selection";
import { GlobalStates } from "../utils/useBetweenDefinition";
import BrownieDetails from "./brownie-details";
import BrownieSize from "./brownie-size";
import BrownieDietaryRequirements from "./brownie-dietary-req";
import BrownieBakeDaySelection from "./brownie-bake-day-selection";
import BrownieFlavourSelection1 from "./brownie-flavour-selection-dropdown1";
import BrownieFlavourSelection2 from "./brownie-flavour-selection-dropdown2";
import BrownieOrderNotes from "./brownie-order-notes";
import BrownieCollectDeliver from "./brownie-collect-deliver";

const browniesSelection = (isMobile) => ({
    background: colours.yellow,
    width: "100%",
    paddingTop: isMobile ? "3%" : "1%",
    paddingBottom: "0px",
    position: "relative",
    margin: 0,
})

const browniesDetailsContent = {
    maxWidth: "800px",
    margin: "auto"
}

const brownieTitle = {
    textAlign: "center",
    color: colours.red,
    margin: "5px 0 10px",
}

const optionTitle = {
    color: colours.red,
    width: "100%",
    textAlign: "center",
    borderBottom: `0.3em solid ${colours.red}`,
    lineHeight: "0.1em",
    margin: "30px 0px 15px"
}

const optionTitleBackground = {
    background: colours.yellow,
    padding: "0 1em"
}

const optionDescription = {
    textAlign: "center",
    margin: "1%"
}

export default function Brownies({browniesAssets, homePageAssets}) {
    const { activeFlavour, isMobile } = useBetween(GlobalStates);
    return (
            <div className={"main-brownie-div"} style={browniesSelection(isMobile)}>
                <FlavourSelection brownies={browniesAssets.edges} />
                <div style={browniesDetailsContent}>
                    <h1 style={brownieTitle}>{browniesAssets.edges[activeFlavour].node.flavour}</h1>
                    <BrownieDetails brownies={browniesAssets.edges}/>

                    <h2 style={optionTitle}><span style={optionTitleBackground}>Flavour 1</span></h2>
                    <BrownieFlavourSelection1 brownies={browniesAssets.edges}/>

                    <h2 style={optionTitle}><span style={optionTitleBackground}>Flavour 2</span></h2>
                    <BrownieFlavourSelection2 brownies={browniesAssets.edges}/>

                    <h2 style={optionTitle}><span style={optionTitleBackground}>Size</span></h2>
                    <p style={optionDescription}>{homePageAssets.sizesDescription}</p>
                    <BrownieSize homePageAssets={homePageAssets}/>

                    <h2 style={optionTitle}><span style={optionTitleBackground}>Diet</span></h2>
                    <p style={optionDescription}>{homePageAssets.dietaryRequirementsDescription}</p>
                    <BrownieDietaryRequirements brownies={browniesAssets.edges} />

                    <h2 style={optionTitle}><span style={optionTitleBackground}>Bake Date</span></h2>
                    <p style={optionDescription}>{homePageAssets.deliveryDateDescription}</p>
                    <BrownieBakeDaySelection />

                    <h2 style={optionTitle}><span style={optionTitleBackground}>Order Notes</span></h2>
                    <p style={optionDescription}>{homePageAssets.orderNotesDescription}</p>
                    <BrownieOrderNotes />

                    <h2 style={optionTitle}><span style={optionTitleBackground}>Delivery or Collection?</span></h2>
                    <p style={optionDescription}>{homePageAssets.deliveryDescription}</p>
                    <BrownieCollectDeliver homePageAssets={homePageAssets} />
                </div>
            </div>
    )
}
