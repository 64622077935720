import React from "react";
import {useBetween} from "use-between";
import {GlobalStates} from "../utils/useBetweenDefinition";

const selectDivStyle = (isMobile) => ({
    width: isMobile ? "95%": "75%",
    margin: "auto"
})

const textAreaStyle = {
    width: "100%",
    height: "5em",
    resize: "none",
    border: "none",
    outline: "none"
}

export default function BrownieOrderNotes() {
    const { setOrderNotes, isMobile } = useBetween(GlobalStates);

    return (
       <div style={selectDivStyle(isMobile)}>
           <textarea style={textAreaStyle} onChange={(e) => setOrderNotes(e.target.value)} />
       </div>
    )
}
