import {useState} from "react";

export const GlobalStates = () => {
    const [activeFlavour, setActiveFlavour] = useState(0);
    const [activeSize, setActiveSize] = useState("0");
    const [activeDietaryRequirements, setActiveDietaryRequirements] = useState("0");
    const [activeBakeDay, setActiveBakeDay] = useState(-1)

    const [useSecondFlavour, setUseSecondFlavour] = useState(false)
    const [activeFlavour1, setActiveFlavour1] = useState(-1)
    const [activeFlavour2, setActiveFlavour2] = useState(-1)
    const [orderNotes, setOrderNotes] = useState("")
    const [discountCode, setDiscountCode] = useState("")
    const [willDeliver, setWillDeliver] = useState("0")

    const [isMobile, setIsMobile] = useState(true)

    return {
        activeSize, setActiveSize,
        activeFlavour, setActiveFlavour,
        activeDietaryRequirements, setActiveDietaryRequirements,
        activeBakeDay, setActiveBakeDay,
        useSecondFlavour, setUseSecondFlavour,
        activeFlavour1, setActiveFlavour1,
        activeFlavour2, setActiveFlavour2,
        orderNotes, setOrderNotes,
        discountCode, setDiscountCode,
        willDeliver, setWillDeliver,
        isMobile, setIsMobile
    }
}
