import React from 'react'
import Header from "../components/header";
import {graphql, StaticQuery} from "gatsby";
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import Brownies from "../components/brownies";
import AboutSuze from "../components/about-suze";
import Footer from "../components/footer";
import {Payments} from "../components/payment";
import { useMediaQuery } from 'react-responsive'
import {useBetween} from "use-between";
import {GlobalStates} from "../utils/useBetweenDefinition";

const alertOptions = {
    position: "bottom center",
    timeout: 3000,
    offset: '20px',
    transition: "scale",
}

const pageStyles = {
    width: "100%",
    margin: 0,
    padding: 0
}

const getBrownieHomePageAssets = (allContentfulHomePage) => {
    const singleLayerIcon = allContentfulHomePage.edges[0].node.singleLayerBrownieIcon
    const doubleLayerIcon = allContentfulHomePage.edges[0].node.doubleLayerBrownieIcon
    const sizesDescription = allContentfulHomePage.edges[0].node.sizesDescription.sizesDescription
    const dietaryRequirementsDescription = allContentfulHomePage.edges[0].node.dietaryRequirementsDescription.dietaryRequirementsDescription
    const deliveryDescription = allContentfulHomePage.edges[0].node.deliveryDescription.deliveryDescription
    const deliveryDateDescription = allContentfulHomePage.edges[0].node.deliveryDateDescription.deliveryDateDescription
    const orderNotesDescription = allContentfulHomePage.edges[0].node.orderNotesDescription.orderNotesDescription
    const priceNormalSize = allContentfulHomePage.edges[0].node.priceNormalSize
    const priceDoubleSize = allContentfulHomePage.edges[0].node.priceDoubleSize
    const regularSizeShipping = allContentfulHomePage.edges[0].node.regularSizeShipping
    const largeSizeShipping = allContentfulHomePage.edges[0].node.largeSizeShipping
    return {
        singleLayerIcon,
        doubleLayerIcon,
        sizesDescription,
        dietaryRequirementsDescription,
        deliveryDescription,
        deliveryDateDescription,
        orderNotesDescription,
        priceNormalSize,
        priceDoubleSize,
        regularSizeShipping,
        largeSizeShipping
    }
}

const IndexPage = ({data}) => {
    const { setIsMobile } = useBetween(GlobalStates)
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })
    React.useEffect(() => {
        setIsMobile(isTabletOrMobileDevice)
    })

  return (
      <StaticQuery query={graphql`
        {
          allContentfulHomePage(filter: {contentful_id: {eq: "2lthCU8cEpcF24zZYnErb2"}}) {
            edges {
              node {
                logo {
                   gatsbyImageData(
                     placeholder: BLURRED
                   )
                }
                doubleLayerBrownieIcon {
                   gatsbyImageData(
                     placeholder: BLURRED
                   )
                }
                singleLayerBrownieIcon {
                   gatsbyImageData(
                     placeholder: BLURRED
                   )
                }
                aboutSuzeImage {
                   gatsbyImageData(
                     placeholder: BLURRED
                   )
                }
                aboutSuzeDescription {
                  aboutSuzeDescription
                }
                sizesDescription {
                  sizesDescription
                }
                deliveryDateDescription {
                  deliveryDateDescription
                }
                dietaryRequirementsDescription {
                  dietaryRequirementsDescription
                }
                orderNotesDescription {
                  orderNotesDescription
                }
                deliveryDescription {
                  deliveryDescription
                }
                regularSizeShipping
                priceNormalSize
                largeSizeShipping
                priceDoubleSize
              }
            }
          }
          allContentfulBrownie {
            edges {
              node {
                id
                flavour
                images {
                   gatsbyImageData(
                     placeholder: BLURRED
                   )
                }
                description {
                  description
                }
                allergyInformation
                canBeVegan
                canBeGlutenFree
                positionId
              }
            }
          }
          allContentfulDiscountCode {
            edges {
              node {
                code
                percentageDiscount
              }
            }
          }
        }
        `
      }
       render={data => (
           <AlertProvider template={AlertTemplate} {...alertOptions}>
               <main style={pageStyles}>
                   <title>SuzeBakes</title>
                   <Header pageAssets={data.allContentfulHomePage.edges[0].node}/>
                   <Brownies browniesAssets={data.allContentfulBrownie} homePageAssets={getBrownieHomePageAssets(data.allContentfulHomePage)} />
                   <Payments browniesAssets={data.allContentfulBrownie.edges} homePageAssets={getBrownieHomePageAssets(data.allContentfulHomePage)} discountCodes={data.allContentfulDiscountCode}/>
                   <AboutSuze aboutDescription={data.allContentfulHomePage.edges[0].node.aboutSuzeDescription.aboutSuzeDescription} aboutImage={data.allContentfulHomePage.edges[0].node.aboutSuzeImage}/>
                   <Footer/>
               </main>
           </AlertProvider>)}
      />
  )
}

export default IndexPage
