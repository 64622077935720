import {Carousel} from "react-responsive-carousel";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import React from "react";
import {useBetween} from "use-between";
import {GlobalStates} from "../utils/useBetweenDefinition";
import {colours} from "../styles/colours";

const brownieDetails = (isMobile) => ({
    backgroundColor: colours.grey,
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
})

const description = (isMobile) => ({
    float: "right",
    width: isMobile ? "100%" : "50%",
    padding: "1em",
    color: colours.yellow
})

const carousel = (isMobile) => ({
    float: "left",
    width: isMobile ? "100%" : "50%",
})

const brownieDescription = {
    marginTop: "0px"
}

const allergyInformationTitle = {
    color: "#999",
    fontSize: "medium",
    margin: "0px"
}

const allergyInformation = {
    ...allergyInformationTitle,
    fontSize: "small"
}

export default function BrownieDetails({brownies}) {
    const { activeFlavour, isMobile} = useBetween(GlobalStates);

    const brownieImages = brownies.map((flavour, flavourIndex) => (
        flavour.node.images.map((im, imageIndex) => (
            <GatsbyImage key={`${flavourIndex}_${imageIndex}`} alt={flavour.node.flavour} image={getImage(im)}/>
        ))
    ))

    const [currentSlide, setCurrentSlide] = React.useState(0)

    React.useEffect(() => {
        setCurrentSlide(0)
    }, [activeFlavour])

    return (
        <div style={brownieDetails(isMobile)}>
            <div style={carousel(isMobile)}>
                <Carousel selectedItem={currentSlide} onChange={index => setCurrentSlide(index)} dynamicHeight={true} showThumbs={false}>
                    {brownieImages[activeFlavour]}
                </Carousel>
            </div>
            <div style={description(isMobile)}>
                <p style={brownieDescription}>{brownies[activeFlavour].node.description.description}</p>
                <p style={allergyInformationTitle}>Allergy Information</p>
                <p style={allergyInformation}>{brownies[activeFlavour].node.allergyInformation}</p>
            </div>
        </div>
    )
}
