import React from "react";
import {colours} from "../styles/colours";

const footer = {
    width: "100%",
    backgroundColor: colours.grey,
    marginBottom: "-30px"
}

const footerContent = {
    maxWidth: "800px",
    margin: "auto",
    paddingTop: "15px"
}

const footerText = {
    margin: "0 auto",
    color: "white",
    textDecoration: "underline",
    textAlign: "center",
    textShadow: "none",
    backgroundImage: "none"
}

const copyrightText = {
    textAlign: "center",
    fontSize: "small",
    color: "#888",
    paddingBottom: "20px",
}

export default function Footer() {
    return (
       <div style={footer}>
           <div style={footerContent}>
               <div style={{margin: "0 auto", display: "flex"}}>
                   <a style={footerText} target="_blank" rel="noreferrer" href={"https://www.instagram.com/suze_bakes/"}>Want to get in contact? Message me on Instagram</a>
               </div>
               <div style={{margin: "0 auto 0px", display: "flex"}}>
                   <a style={footerText} target="_blank" rel="noreferrer" href={"mailto:suze.eats@gmail.com"}>or email at suze.eats@gmail.com</a>
               </div>
               <div>
                   <p style={copyrightText}>{'\u00A9 ' + (new Date()).getFullYear()} Copyright SuzeBakes </p>
               </div>
           </div>
       </div>
    )
}
