import React, {useEffect, useState} from "react";
import {useBetween} from "use-between";
import Select from 'react-select';
import {GlobalStates} from "../utils/useBetweenDefinition";
import { getBakeDaysFromDynamo } from "../utils/dynamo";

const selectDivStyle = (isMobile) => ({
    width: isMobile ? "95%": "75%",
    margin: "auto"
})

const convertBakeDayFormat = (bakeDay) => {
    const splitDate = bakeDay.split("-")
    return splitDate.reverse().join("/")
}

const getBakeDays = () =>
    getBakeDaysFromDynamo()
            .then(dynamoResponse => {
                if (dynamoResponse.Items === []) {
                    return {
                        value: "-1",
                        label: "Sorry, check back another time!"
                    }
                }

                const days = dynamoResponse.Items.map((day) => ({
                        value: day.Date,
                        label: `${convertBakeDayFormat(day.Date)}: ${day.RemainingOrders} Remaining`
                    }))
                return days.sort((a, b) => (
                    Date.parse(a.value) > Date.parse(b.value) ? 1 : -1
                ))
                }
            )

export default function BrownieBakeDaySelection() {
    const { setActiveBakeDay, isMobile } = useBetween(GlobalStates);
    const [ bakeDays, setBakeDays ] = useState({})

    useEffect(() => {
        getBakeDays()
            .then(options => setBakeDays(options))
    }, [])

    return (
       <div style={selectDivStyle(isMobile)}>
           <Select instanceId={2} options={bakeDays} onChange={selected => {
               return setActiveBakeDay(selected.value)
           }}/>
       </div>
    )
}
